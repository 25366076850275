<template>
  <div class="addressList bgc-F7F7F7">
    <navbarTools :leftArrow="true"
                 :fixed="true"></navbarTools>
    <div class="jd-ship-address-wrap"
         :class="{ 'bgc-F7F7F7': true }">
      <div>
        <!-- 无收货地址 -->
        <div class="pt65 text-center fs16 c-999"
             v-if="!addressList.length">
          <div class="fs0 line-height-0 mb14">
            <img class="h-60"
                 :src="OSS_URL + '/address/ship-address.png'" />
          </div>
          <div>暂无收获地址哦～</div>
        </div>

        <!-- 有收货地址 -->
        <div class="pt10"
             v-else>
          <div class="bgc-fff pl15 pr15">
            <div class="address-item-wrap pt20 pb18 jd-border-top"
                 v-for="(item, index) in addressList"
                 :key="index"
                 @click="chooseAddressFn(item)">
              <div class="flex justify-space-between fs16 line-height-16 c-333">
                <div class="font-weight flex text-left">
                  <div class="address-name">{{ item.name }}</div>
                  <div class="ml10">{{ item.mobile }}</div>
                </div>
                <div class="c-999 fs12 flex">
                  <span class="mr20 flex"
                        @click.stop="addShipAddressFn('edit', '编辑', item.userAddressId)">
                    <span class="icon iconfont iconbianji-3 fs16 mr5"></span>
                    <span>编辑</span>
                  </span>
                  <span class="flex"
                        @click.stop="delAddressFn(item.userAddressId)">
                    <span class="icon iconfont iconshanchulaji-xianxing fs16 mr5"></span>
                    <span>删除</span>
                  </span>
                </div>
              </div>
              <div class="mt15 c-333 fs12 line-height-18">
                {{ item.provinceName }} {{ item.cityName }}
                {{ item.areaName }} {{ item.townName }}
                {{ item.address }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="h-60 iphonex-bottom"></div>
      <!-- 新建收货地址按钮 -->
      <div class="jd-btn-fixed-bottom iphonex-bottom">
        <div class="radius3 bgc-FE6507 line-height-40 fs16 c-fff text-center mt5"
             @click="addShipAddressFn('add', '新建')">
          <span class="icon iconfont iconjiahao fs18 mr2"></span>
          新建收货地址
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import navbarTools from '@/components/navbarTools'
  import { toRefs, defineComponent, reactive } from "vue";
  import { getAddressList, doAddressDelete } from '@/api/shop'
  import { useRouter, useRoute } from 'vue-router'
  import { Dialog } from 'vant';
  import { Toast } from 'vant';
  export default defineComponent({
    components: {
      navbarTools
    },
    setup() {
      const $router = useRouter();
      const state = reactive({
        OSS_URL: process.env.VUE_APP_OSS_URL,
        addressList: []
      })
      const getAddressListFn = async () => {
        let rs = await getAddressList();
        if (rs.code === 0) {
          state.addressList = rs.data
          let jdChooseAddress = sessionStorage.getItem('jdChooseAddress');
          if (jdChooseAddress) {
            let addressId = JSON.parse(jdChooseAddress).userAddressId;
            let index = state.addressList.findIndex(item => item.userAddressId == addressId);
            if (index > -1) {
              sessionStorage.setItem("jdChooseAddress", JSON.stringify(state.addressList[index]));
            }
          }
        }

      }
      const chooseAddressFn = (item) => {
        sessionStorage.setItem("jdChooseAddress", JSON.stringify(item));
        $router.go(-1);
      }
      const delAddressFn = (addressId) => {
        Dialog.confirm({
          title: '',
          message: '确定要删除该地址吗?',
        })
          .then(async () => {
            // on confirm
            let rs = await doAddressDelete({ addressId });
            if (rs.code === 0) {
              let jdChooseAddress = sessionStorage.getItem('jdChooseAddress');
              if (jdChooseAddress) {
                if (addressId == JSON.parse(jdChooseAddress).userAddressId) {
                  sessionStorage.removeItem('jdChooseAddress');
                }
              }
              Toast(rs.data);
              getAddressListFn();
            }
          })
          .catch(() => {
            // on cancel
          });
      }
      const addShipAddressFn = (type, text, addressId) => {
        $router.replace({
          path: '/address/editAddress',
          query: {
            type, text, addressId
          }
        })
      }
      getAddressListFn();
      return {
        ...toRefs(state),
        chooseAddressFn,
        addShipAddressFn,
        delAddressFn,
      }
    }
  });
</script>
<style lang="scss" scoped>
  .addressList {
    height: 100%;
    ::v-deep(.navbar) {
      width: 375px;
      .van-nav-bar {
        .van-nav-bar__left .van-icon {
          font-size: 24px;
          color: #000;
        }
        .van-nav-bar__title {
          font-size: 18px;
          font-weight: 600;
        }
        .van-nav-bar__right .van-nav-bar__text {
          color: #333;
        }
      }
    }
    .jd-ship-address-wrap {
      position: relative;
      top: 46px;
      .address-item-wrap {
        &:first-child {
          border-top: 0;
        }
        .address-name {
          max-width: 65px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
</style>